<template>
    <div class="px-3 py-2">
        <v-form
            ref="form"
            lazy-validation
        >
        <b-sidebar backdrop id="addTicket" width="700px" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right title="اضافة مهمة" shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>اضافة دعم فني</span>
        </div>
        <div @click="hide" style="text-align:center;width:100px;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>اغلاق</span>
      </div>
    </div>
            <v-divider></v-divider>
            <v-row class="m-2">
                <v-col cols="12" md="12" sm="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="cusotmer"
                        :items="customersList"
                        label="اختر العميل"
                        :rules="nameRules"
                        required 
                    ></v-select>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="response"
                        :items="users"
                        label="المسئول"
                        :rules="nameRules"
                        required 
                    ></v-select>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        <v-textarea
                            v-model="message"
                            label="تفاصيل الطلب"
                            value=""
                            :rules="nameRules"
                            required
                            ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date"
                                label="الموعد النهائي"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="date"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                
            </v-row>
    </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" @click="addTask()" variant="success" class="ma-2" style="width:100px;">إضافة</b-button>
                </div>
            </template>
        </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import {mapGetters} from 'vuex'
export default{
    data() {
        return {
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            response: 0,
            users:[],
            message: '',
             nameRules: [
                v => !!v || 'هذا الحقل مطلوب',
            ],
            cusotmer: 0
        }
    },
    computed: {
        ...mapGetters({
            supportState: `customerSupport/supportState`
        }),
        customersList: function(){
            let t = [];
            for(let i=0;i<this.supportState.clientList.length;i++){
                t.push({
                    text: this.supportState.clientList[i].full_name,
                    value: this.supportState.clientList.id
                })
            }
            return t;
        }
    },
    methods: {
        getAgents(){
            const post = new FormData();
            post.append("type","getAgents");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]","all");
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.users = res.results.data.agents;
                this.agentid = res.results.data.myID;
            });
        },
        addTask(){
            if(!this.$refs.form.validate()){
                return false;
            }
            if(this.response == ''){
                return false;
            }
            if(this.message == ''){
                return false;
            }
            const post = new FormData();
            post.append("type","addzSupport");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[response]",this.response);
            post.append("data[message]",this.message);
            post.append("data[deadline]",this.date);
            post.append("data[cusotmer]",this.cusotmer);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            )
            .then((resposne) => {
                const res = resposne.data;
                console.log(res);
                this.response = 0;
                this.message = '';
                this.customer = 0;
            })
        }
    },
    created() {
        this.getAgents();
    },
}
</script>